import React from 'react'
import classnames from 'classnames'

const PageHeader = ({ title, subtitle, className }) => {
  const classes = classnames(
    'page-header d-flex justify-content-center text-center',
    {
      'has-subtitle': subtitle
    }
  )

  return (
    <header className={classes}>
      <div className="col-12 col-md-6">
        <h1>{title}</h1>
        {subtitle ? <h2 className="text-small">{subtitle}</h2> : null}
      </div>
    </header>
  )
}

export default PageHeader
