import React from 'react'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import { StaticQuery, graphql } from 'gatsby'
import '../styles/main.scss'

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query NavigationQuery {
        site {
          siteMetadata {
            navigation {
              path
              label
              externalLink
              mobileImage
            }
            footerNavigation {
              groupTitle
              items {
                path
                externalLink
                label
                target
              }
            }
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <Navigation {...data.site.siteMetadata} />
        <div>{children}</div>
        <Footer {...data.site.siteMetadata} />
      </React.Fragment>
    )}
  />
)
