import React, { Component } from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'

import Icon from '../components/Icon'

// import all navigation images
// const navImages =
const importAll = request => {
  const imports = {}

  request.keys().forEach((key, index) => {
    imports[key] = request.keys().map(request)[index]
  })

  return imports
}

const navImages = importAll(
  require.context('../images/navigation', false, /\.(png|jpe?g|svg)$/)
)

class Navigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navVisible: false
    }
    this.toggleNav = this.toggleNav.bind(this)
  }
  renderNavItems() {
    const navItems = this.props.navigation
    return navItems.map(
      ({ path, label, mobileImage, externalLink = false }) => {
        const img = navImages[`./${mobileImage}`]
        return (
          <li className="nav-item" key={path}>
            {externalLink ? (
              <a href={path} className="nav-link" rel="noopener">
                {img && <img src={img} alt={label} />}
                <span>{label}</span>
              </a>
            ) : (
              <Link className="nav-link" to={path}>
                {img && <img src={img} alt={label} />}
                <span>{label}</span>
              </Link>
            )}
          </li>
        )
      }
    )
  }
  toggleNav() {
    this.setState({ navVisible: !this.state.navVisible })
  }
  render() {
    const className = classnames('nav-menu', {
      'is-visible': this.state.navVisible
    })
    return (
      <nav
        className={`navbar navbar-expand navbar-dark flex-column flex-md-row`}
      >
        <div className="container">
          <a href="https://tierion.com" className="navbar-brand mb-0">
            <span className="sr-only">tierion</span>
            <Icon type="logo-white" />
          </a>
          <div className="nav-menu-container">
            <div className={className}>
              <div className="mobile-nav-label">Products</div>
              <ul className="navbar-nav bd-navbar-nav flex-row">
                {this.renderNavItems()}
              </ul>
            </div>
            <button className="nav-burger" onClick={this.toggleNav}>
              <Icon type={this.state.navVisible ? 'close' : 'burger'} />
            </button>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navigation
